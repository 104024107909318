import React, { useState, useEffect } from 'react'
import { Typography, Flex, H4 } from '@components/atoms'
import { SharedBtn, SnsButton, LikeBtn } from '@components/molecules'
import {
  MailCopiedPopup,
  MailPopup,
  LinkCopiedPopup,
} from '@components/organisms'
import Textarea from 'react-textarea-autosize'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { auth_artist_img } from '@images/'
import styled from 'styled-components'
import { DOMAIN_URL } from '@consts/'
import OkCancelPopup from '../Popup/OkCancelPopup'

const SnsList = styled(Flex)`
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  align-self: flex-start;

  div:first-child,
  div:nth-child(7) {
    margin-left: 0px !important;
  }

  div:last-child,
  div:nth-child(6) {
    margin-right: 0px !important;
  }
`

const ArtistCard = ({
  // sns,
  name,
  uniqueName,
  stageName,
  artistImage,
  artistText,
  isDisplayDescription,
  currentPath,
  facebookUrl,
  instagramUrl,
  youtubeUrl,
  soundcloudUrl,
  homepageUrl,
  emailUrl,
  melonUrl,
  twitterUrl,
  weverseUrl,
  etcSocialUrl,
  tiktokUrl,
  updateRecord,
  setIsShowLoginPopup,
  setShowLikePopup,
  likeInfo,
  updateLike,
  artistId,
  currentUser,
  subdomainInfo,
  isWebPlamUser,
}) => {
  const {
    isShowing: unlikeConfirmShowing,
    toggle: unlikeConfirmToggle,
  } = OkCancelPopup.usePopup()

  const [more, setMore] = useState(false) // 더보기
  const [haveMore, setHaveMore] = useState(false) // 더보기 유무
  const [showMail, setShowMail] = useState(false) // 이메일 주소 복사 박스
  const [mailCopied, setMailCopied] = useState(false) // 이메일 주소 완료 팝업
  const [mail, setMail] = useState() // 메일
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const loadMailPopup = value => {
    setMail(value)
    setShowMail(true)
  }

  const handleCopy = () => {
    setMailCopied(true)
    setTimeout(() => setMailCopied(false), 2500)
  }

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  const handleLike = () => {
    if (currentUser) {
      if (likeInfo.isLike) {
        unlikeConfirmToggle()
      } else {
        updateLike('add', currentUser._id, artistId, 'artist')
        setShowLikePopup(true)
      }
    } else {
      setIsShowLoginPopup(true)
    }
  }

  // 더보기 체크 함수
  useEffect(() => {
    if (document.getElementById('description_wrapper')) {
      const boxHeight = document.getElementById('description_wrapper')
        .clientHeight
      if (boxHeight > 54) {
        setHaveMore(true)
      } else {
        setHaveMore(false)
      }
    }
  }, [
    document.getElementById('description_wrapper') &&
      document.getElementById('description_wrapper').clientHeight,
  ])

  return (
    // 아티스트 카드
    <>
      <Flex
        type="column"
        style={{
          padding: '0',
          height: 'auto',
          // overflow: 'hidden',
        }}
      >
        <Flex
          style={{
            width: '100%',
            height: '136px',
            margin: '0px 0 33px 0',
            justifyContent: 'center',
          }}
        >
          <Flex
            style={{
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <H4
              type="Medium"
              color="#ffffff"
              style={{
                display: 'inline-block',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
                alignSelf: 'flex-end',
                zIndex: 2,
              }}
            >
              {stageName}
              {isWebPlamUser && (
                <img
                  src={auth_artist_img}
                  style={{ width: 20, height: 20, margin: '0 0 -4px 4px' }}
                  alt="auth_artist_icon"
                />
              )}
            </H4>
            <Flex
              type="column"
              style={{
                zIndex: 2,
                marginLeft: '10px',
                width: '50px',
                right: '20px',
                height: '156px',
              }}
            >
              <CopyToClipboard
                text={
                  subdomainInfo &&
                  subdomainInfo.subdomain &&
                  subdomainInfo.isAccepted
                    ? `${subdomainInfo.subdomain}.${DOMAIN_URL}`
                    : currentPath
                }
                onCopy={() => linkCopy()}
              >
                <SharedBtn
                  style={{ paddingTop: '20px', margin: '0px 0 18px 0' }}
                />
              </CopyToClipboard>
              <LikeBtn
                active={likeInfo && likeInfo.isLike}
                count={likeInfo && likeInfo.likeCount}
                onClick={() => {
                  handleLike()
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          type="column"
          style={{
            // overflow: 'hidden',
            height: 'auto',
          }}
        >
          <SnsList>
            {homepageUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="homepage"
                link={homepageUrl}
                onClick={() => updateRecord('homepageUrl', homepageUrl)}
              />
            )}
            {instagramUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="instagram"
                link={instagramUrl}
                onClick={() => updateRecord('instagramUrl', instagramUrl)}
              />
            )}
            {twitterUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="twitter"
                link={twitterUrl}
                onClick={() => updateRecord('twitterUrl', twitterUrl)}
              />
            )}
            {weverseUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="weverse"
                link={weverseUrl}
                onClick={() => updateRecord('weverseUrl', weverseUrl)}
              />
            )}
            {youtubeUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="youtube"
                link={youtubeUrl}
                onClick={() => updateRecord('youtubeUrl', youtubeUrl)}
              />
            )}
            {soundcloudUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="soundcloud"
                link={soundcloudUrl}
                onClick={() => updateRecord('soundcloudUrl', soundcloudUrl)}
              />
            )}
            {facebookUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="facebook"
                link={facebookUrl}
                onClick={() => updateRecord('facebookUrl', facebookUrl)}
              />
            )}
            {tiktokUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="tiktok"
                link={tiktokUrl}
                onClick={() => updateRecord('tiktokUrl', tiktokUrl)}
              />
            )}
            {emailUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="email"
                link={emailUrl}
                onClick={() => loadMailPopup(emailUrl)}
              />
            )}
            {etcSocialUrl && (
              <SnsButton
                style={{ padding: '7px 14px 7px 0px' }}
                type="etc"
                link={etcSocialUrl}
                onClick={() => updateRecord('etcSocialUrl', etcSocialUrl)}
              />
            )}
            {/* {sns &&
              sns.map(
                item =>
                  Object.values(item) !== '' && (
                    <SnsButton
                      style={{ padding: '7px 14px 7px 0px' }}
                      key={Object.keys(item)}
                      type={Object.keys(item)}
                      link={Object.values(item)}
                      onClick={() => loadMailPopup(Object.values(item))}
                    />
                  ),
              )} */}
          </SnsList>

          {isDisplayDescription && (
            <Flex style={{ justifyContent: 'space-between' }}>
              {artistText ? (
                <Flex type="column" style={{ width: '100%' }}>
                  <Flex
                    style={{
                      width: '100%',
                      maxHeight: more ? 'max-content' : '54px',
                      overflow: 'hidden',
                    }}
                  >
                    <Textarea
                      id="description_wrapper"
                      readOnly
                      value={artistText}
                      className="textarea"
                      style={{ width: '100%', fontSize: '12px' }}
                      minRows={1}
                      // maxRows={haveMore ? 1000 : 3}
                    />
                  </Flex>
                  {haveMore && !more && (
                    <Typography
                      type="Medium"
                      size="12px"
                      color="#949494"
                      onClick={() => setMore(true)}
                      style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                    >
                      더보기
                    </Typography>
                  )}
                </Flex>
              ) : (
                <Typography type="Medium" size="12px">
                  등록된 설명이 없습니다.
                </Typography>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {showMail && (
        <MailPopup
          onCopy={handleCopy}
          name={stageName}
          mail={mail}
          setShowMail={setShowMail}
        />
      )}
      {mailCopied && <MailCopiedPopup />}
      {isCopied && <LinkCopiedPopup />}

      <OkCancelPopup.View
        isShowing={unlikeConfirmShowing}
        toggle={unlikeConfirmToggle}
        onCancel={unlikeConfirmToggle}
        onOk={() => {
          updateLike('delete', currentUser._id, artistId, 'artist')
        }}
        title={'좋아요 취소시 작성한 댓글이\n 모두 비공개 처리됩니다.'}
      />
    </>
  )
}

export default ArtistCard
